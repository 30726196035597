import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';
import config from '@utils/config';

Sentry.init({
    environment: config.environment,
    dsn: config.sentry.dsn,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: Number(config.sentry.tracesSampleRate),
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});
