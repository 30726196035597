import { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

// Internal Component
import Index from '@pages/Complement/LoadingPage';

import { RouteItem } from '@typings/Route.d';
import { routes } from '@utils/routes';
import { StoreConsumer, StoreProvider } from '@utils/Store/Store';
import { RoleContext, RoleProvider } from '@utils/providers/RoleProvider';
import { Toaster } from 'react-hot-toast';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import config from '@utils/config';

dayjs.extend(isBetween);
dayjs.extend(isToday);

// Create a client
const queryClient = new QueryClient();

const App = () => {
    const gtmParams = {
        id: String(config.gtmID),
    };

    return (
        <GTMProvider state={gtmParams}>
            <StoreProvider initialValue={{ authenticated: false }}>
                <RoleProvider>
                    <RoleContext.Consumer>
                        {(context: any) =>
                            !context?.state?.isLoading ? (
                                <Suspense fallback={<Index />}>
                                    <StoreConsumer>
                                        {(store) => (
                                            <QueryClientProvider
                                                client={queryClient}
                                            >
                                                <Router>
                                                    <Routes>
                                                        {routes.map(
                                                            (item: RouteItem) => (
                                                                <Route
                                                                    key={item.path}
                                                                    path={item.path}
                                                                    element={
                                                                        <item.component
                                                                            authenticated={
                                                                                store
                                                                                    ?.state
                                                                                    ?.authentication
                                                                                    .isAuthenticated
                                                                            }
                                                                            roles={
                                                                                item.roles
                                                                            }
                                                                        />
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </Routes>
                                                </Router>
                                            </QueryClientProvider>
                                        )}
                                    </StoreConsumer>
                                    <Toaster />
                                </Suspense>
                            ) : (
                                <Index />
                            )
                        }
                    </RoleContext.Consumer>
                </RoleProvider>
            </StoreProvider>
        </GTMProvider>
    );
};

export default App;
