import { lazy } from 'react';
import { ROLES, RouteItem } from '@typings/Route.d';

export const routes: RouteItem[] = [
    {
        name: 'Home',
        path: '/',
        exact: true,
        component: lazy(() => import('@pages/Authentication/Login')),
    },
    // TODO<riez>: Add :hash
    {
        name: 'ClassSeating',
        path: '/class-seating/:hash',
        exact: true,
        component: lazy(() => import('@pages/ClassSeating')),
    },
    {
        name: 'Login',
        path: '/login',
        exact: true,
        component: lazy(() => import('@pages/Authentication/Login')),
    },
    {
        name: 'Forgot Password',
        path: '/forgot-password',
        exact: true,
        component: lazy(() => import('@pages/Authentication/ForgotPassword')),
    },
    {
        name: 'Reset Password',
        path: '/reset-password',
        exact: true,
        component: lazy(() => import('@pages/Authentication/ResetPassword')),
    },
    {
        name: 'Schedule',
        path: '/schedule',
        exact: true,
        showOnNavbar: true,
        component: lazy(() => import('@pages/Schedule')),
    },
    {
        name: 'Participation Tracker',
        path: '/participation-tracker/:sessionId',
        exact: true,
        showOnNavbar: false,
        component: lazy(() => import('@pages/ParticipationTracker')),
    },
    {
        name: 'Teacher Feedback',
        path: '/feedback',
        exact: true,
        showOnNavbar: true,
        component: lazy(() => import('@pages/TeacherFeedbackList')),
        additionalComponents: ['notification-bubble'],
        roles: [ROLES.TEACHER],
    },
    {
        name: 'Teacher Feedback Form',
        path: '/feedback/:sessionId',
        exact: true,
        showOnNavbar: false,
        component: lazy(() => import('@pages/TeacherFeedback/TeacherFeedbackV2')),
        roles: [ROLES.TEACHER],
    },
    {
        name: 'Authentication Verify',
        path: '/authentication/verify',
        exact: true,
        showOnNavbar: false,
        component: lazy(() => import('@pages/Authentication/AuthenticationVerify')),
    },
];
