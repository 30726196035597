import React, { FunctionComponent, ReactNode } from 'react';
import update from 'immutability-helper';
import { CommonDialogPayload } from '@utils/Store/ActionPayload/CommonDialog';
import { AuthStatePayload } from '@utils/Store/ActionPayload/AuthState';
import { ConfirmDialogPayload } from './ActionPayload/ConfirmDialog';

interface Props {
    children: React.ReactNode;
    initialValue?: Record<string, any>;
}

export enum ActionTypes {
    OPEN_COMMON_DIALOG,
    OPEN_CONFIRM_DIALOG,
    CLOSE_CONFIRM_DIALOG,
    UPDATE_AUTH_STATE,
}

const initialState = {
    authentication: {
        isAuthenticated: false,
        sessionUUID: '',
    },
    commonDialog: {
        show: false,
        title: '',
        message: '',
    },
    confirmDialog: {
        show: false,
        title: '',
        message: '',
    },
};

export type ActionPayload =
    | AuthStatePayload
    | CommonDialogPayload
    | ConfirmDialogPayload;

const Store = React.createContext<{
    state: typeof initialState;
    dispatch: React.Dispatch<{
        type: ActionTypes;
        payload?: ActionPayload;
    }>;
}>(null!);

function reducer(
    state: typeof initialState,
    action: {
        type: ActionTypes;
        payload?: ActionPayload;
    }
): typeof initialState {
    const payload = action.payload;
    switch (action.type) {
        case ActionTypes.OPEN_COMMON_DIALOG:
            return update(state, {
                commonDialog: {
                    show: { $set: true },
                    title: { $set: (payload as CommonDialogPayload).title },
                    message: { $set: (payload as CommonDialogPayload).message },
                },
            });
        case ActionTypes.OPEN_CONFIRM_DIALOG:
            return update(state, {
                confirmDialog: {
                    show: { $set: true },
                    title: { $set: (payload as ConfirmDialogPayload).title },
                    message: { $set: (payload as ConfirmDialogPayload).message },
                },
            });
        case ActionTypes.CLOSE_CONFIRM_DIALOG:
            return update(state, {
                confirmDialog: {
                    show: { $set: false },
                    title: { $set: '' },
                    message: { $set: '' },
                },
            });
        case ActionTypes.UPDATE_AUTH_STATE:
            return update(state, {
                authentication: {
                    isAuthenticated: {
                        $set: (payload as AuthStatePayload).authenticated,
                    },
                    sessionUUID: { $set: (payload as AuthStatePayload).sessionUUID },
                },
            });
        default:
            return state;
    }
}

export const StoreProvider: FunctionComponent<Props> = React.memo((props: Props) => {
    const { children, initialValue = {} } = props;
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const value = { state, dispatch };
    return (
        <Store.Provider value={Object.assign({}, value, initialValue)}>
            {children}
        </Store.Provider>
    );
});

export const StoreConsumer = Store.Consumer;

export default Store;
