/* eslint-disable */
// @ts-nocheck
/*
 * This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
 */

import * as fm from '@generated/fetch.pb';
import * as GoogleProtobufEmpty from '../../../google/protobuf/empty.pb';
export type V2GetFeedbackAnswersResponse = {
    answers?: V2Answer[];
};

export type V2Answer = {
    id?: number;
    value?: string;
};

export type V2GetFeedbackQuestionsResponse = {
    questions?: V2Question[];
};

export type V2Question = {
    id?: number;
    value?: string;
};

export type V2GetTeacherFeedbackDetailsRequest = {
    sessionId?: string;
    withString?: boolean;
};

export type V2GetTeacherFeedbackDetailsResponse = {
    levelFeedbacks?: V2LevelFeedback[];
};

export type V2LevelFeedback = {
    level?: V2LevelInfo;
    feedbacks?: V2Feedback[];
};

export type V2LevelInfo = {
    name?: string;
    objective?: string;
    questionIds?: number[];
};

export type V2Feedback = {
    student?: V2Student;
    comfortLevelId?: number;
    participationLevelId?: number;
    positiveStandout?: string;
    answerIds?: number[];
    flagIds?: number[];
    remark?: string;
    comfortLevel?: string;
    participationLevel?: string;
    flags?: string[];
    attendanceInfo?: V2AttendanceInfo;
};

export type V2AttendanceInfo = {
    attendance?: boolean;
    punctuality?: boolean;
};

export type V2Student = {
    id?: string;
    fullName?: string;
    nickname?: string;
};

export type V2GetComfortParticipationLevelsResponse = {
    comfortLevels?: ComfortLevel[];
    participationLevels?: ParticipationLevel[];
};

export type ComfortLevel = {
    id?: number;
    name?: string;
    participationLevelIds?: number[];
};

export type ParticipationLevel = {
    id?: number;
    name?: string;
};

export type V2GetFeedbackFlagResponse = {
    feedbackFlags?: FeedbackFlag[];
};

export type FeedbackFlag = {
    id?: number;
    feedback?: string;
};

export type V2TeacherFeedbackRecord = {
    studentId?: string;
    attendance?: boolean;
    comfortLevelId?: number;
    participationLevelId?: number;
    positiveStandout?: string;
    questionIds?: number[];
    answerIds?: number[];
    flagIds?: number[];
    remark?: string;
    attendanceInfo?: V2AttendanceInfo;
};

export type V2PostTeacherFeedbackDetailsRequest = {
    sessionId?: string;
    requester?: string;
    feedbacks?: V2TeacherFeedbackRecord[];
};

export class TeacherFeedbackV2 {
    static GetV2FeedbackAnswers(
        req: GoogleProtobufEmpty.Empty,
        initReq?: fm.InitReq
    ): Promise<V2GetFeedbackAnswersResponse> {
        return fm.fetchReq<GoogleProtobufEmpty.Empty, V2GetFeedbackAnswersResponse>(
            `/v2/feedback/answers?${fm.renderURLSearchParams(req, [])}`,
            { ...initReq, method: 'GET' }
        );
    }
    static GetV2FeedbackQuestions(
        req: GoogleProtobufEmpty.Empty,
        initReq?: fm.InitReq
    ): Promise<V2GetFeedbackQuestionsResponse> {
        return fm.fetchReq<
            GoogleProtobufEmpty.Empty,
            V2GetFeedbackQuestionsResponse
        >(`/v2/feedback/questions?${fm.renderURLSearchParams(req, [])}`, {
            ...initReq,
            method: 'GET',
        });
    }
    static GetV2FeedbackFlags(
        req: GoogleProtobufEmpty.Empty,
        initReq?: fm.InitReq
    ): Promise<V2GetFeedbackFlagResponse> {
        return fm.fetchReq<GoogleProtobufEmpty.Empty, V2GetFeedbackFlagResponse>(
            `/v2/feedback/feedbackFlags?${fm.renderURLSearchParams(req, [])}`,
            { ...initReq, method: 'GET' }
        );
    }
    static GetV2FeedbackComfortParticipationLevels(
        req: GoogleProtobufEmpty.Empty,
        initReq?: fm.InitReq
    ): Promise<V2GetComfortParticipationLevelsResponse> {
        return fm.fetchReq<
            GoogleProtobufEmpty.Empty,
            V2GetComfortParticipationLevelsResponse
        >(
            `/v2/feedback/comfortParticipationLevels?${fm.renderURLSearchParams(
                req,
                []
            )}`,
            { ...initReq, method: 'GET' }
        );
    }
    static GetV2FeedbackDetails(
        req: V2GetTeacherFeedbackDetailsRequest,
        initReq?: fm.InitReq
    ): Promise<V2GetTeacherFeedbackDetailsResponse> {
        return fm.fetchReq<
            V2GetTeacherFeedbackDetailsRequest,
            V2GetTeacherFeedbackDetailsResponse
        >(
            `/v2/feedback/sessions/${
                req['sessionId']
            }/details?${fm.renderURLSearchParams(req, ['sessionId'])}`,
            { ...initReq, method: 'GET' }
        );
    }
    static PostV2FeedbackDetails(
        req: V2PostTeacherFeedbackDetailsRequest,
        initReq?: fm.InitReq
    ): Promise<GoogleProtobufEmpty.Empty> {
        return fm.fetchReq<
            V2PostTeacherFeedbackDetailsRequest,
            GoogleProtobufEmpty.Empty
        >(`/v2/feedback/sessions/${req['sessionId']}/details`, {
            ...initReq,
            method: 'POST',
            body: JSON.stringify(req, fm.replacer),
        });
    }
}
