import { Paula as WrappedPaula } from '@generated/edukita/paula-backend/v1/service.pb';
import { TeacherFeedbackV2 as WrappedTeacherFeedback } from '@generated/edukita/paula-backend/v2/teacher_feedback_service.pb';
import { PythagorasV2 as WrappedPythagoras } from '@generated/edukita/paula-backend/v2/pythagoras_service.pb';
import config from '@utils/config';

const API_ENDPOINT = config.backendURI;
const prefix = { pathPrefix: API_ENDPOINT };

const checkError = (response: any): any => {
    if (response.code != null) {
        throw new Error("Something's Wrong");
    }
    return response;
};

const createProxy = (service: any): any => {
    const handler = {
        get(target: any, prop: any, _: any) {
            if (target[prop] && typeof target[prop] === 'function') {
                return async (...args: any[]) => {
                    args.push(prefix);
                    const response = await target[prop](...args);
                    return checkError(response);
                };
            }
            return target[prop];
        },
    };
    return new Proxy(service, handler);
};

export const PaulaClient = createProxy(WrappedPaula);

export const TeacherFeedbackV2Client = createProxy(WrappedTeacherFeedback);

export const PythagorasV2Client = createProxy(WrappedPythagoras);

// CamelCase to snake_case
// expect to define T for the input
type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
    ? `${T extends Capitalize<T> ? '_' : ''}${Lowercase<T>}${CamelToSnakeCase<U>}`
    : S;

type CamelToSnakeCaseObject<T> = {
    [K in keyof T as CamelToSnakeCase<Extract<K, string>>]: T[K] extends object
        ? CamelToSnakeCaseObject<T[K]>
        : T[K];
};

export function camelToSnakeCase<T>(obj: T): CamelToSnakeCaseObject<T> {
    const toSnakeCase = (str: string) =>
        str.replace(/([A-Z]+)(?=[A-Z][a-z]|[0-9]|$)|([A-Z])/g, (match, p1, p2) => {
            // Special case for "ID"
            if (match === 'ID') return '_id';
            if (p1) return `_${p1.toLowerCase()}`;
            if (p2) return `_${p2.toLowerCase()}`;
            return '';
        });

    const isObject = (val: unknown): val is object =>
        val !== null && typeof val === 'object';

    const convert = (input: any): any => {
        if (!isObject(input)) {
            return input;
        }

        if (Array.isArray(input)) {
            return input.map(convert);
        }

        return Object.keys(input).reduce((acc, key) => {
            const newKey = toSnakeCase(key);
            acc[newKey] = convert((input as any)[key]);
            return acc;
        }, {} as Record<string, unknown>);
    };

    return convert(obj) as CamelToSnakeCaseObject<T>;
}

type SnakeToCamelCase<S extends string> = S extends `${infer T}_${infer U}`
    ? `${T}${Capitalize<SnakeToCamelCase<U>>}`
    : S;

export function snakeToCamelCase<T>(obj: any): T {
    const toCamelCase = (str: string) =>
        str.replace(/_id|_[a-z]/g, (match) => {
            // Special case for "_id"
            if (match === '_id') return 'ID';
            return match[1].toUpperCase();
        });

    const isObject = (val: unknown): val is object =>
        val !== null && typeof val === 'object';

    const convert = (input: any): any => {
        if (!isObject(input)) {
            return input;
        }

        if (Array.isArray(input)) {
            return input.map(convert);
        }

        return Object.keys(input).reduce((acc, key) => {
            const newKey = toCamelCase(key);
            acc[newKey] = convert((input as any)[key]);
            return acc;
        }, {} as Record<string, unknown>);
    };

    return convert(obj) as T;
}
