import update from 'immutability-helper';
import { PaulaClient } from '@apis/utils';
import { ROLES } from '@typings/Route.d';
import { createContext, Dispatch, useMemo, useReducer } from 'react';

interface RolePayload {
    roleName: Array<ROLES>;
    userId: string;
    email: string;
}

export enum ActionTypes {
    UPDATE_ROLE,
}

const initialState: { data: RolePayload; isLoading: boolean } = {
    data: {
        roleName: [],
        userId: '',
        email: '',
    },
    isLoading: false,
};

const RoleContext = createContext<{
    state: typeof initialState;
    dispatch: Dispatch<{
        type: ActionTypes;
        payload: {
            data: RolePayload;
            isLoading: boolean;
        };
    }>;
}>(null!);

function roleReducer(
    state: typeof initialState,
    action: {
        type: ActionTypes;
        payload: {
            data: RolePayload;
            isLoading: boolean;
        };
    }
): typeof initialState {
    const { payload } = action;
    switch (action.type) {
        case ActionTypes.UPDATE_ROLE: {
            return update(state, {
                data: {
                    roleName: {
                        $set: payload?.data?.roleName,
                    },
                    userId: { $set: payload.data.userId },
                    email: { $set: payload.data.email },
                },
                isLoading: { $set: payload.isLoading },
            });
        }
        default:
            return state;
    }
}

const updateRole = async (
    dispatch: Dispatch<{
        type: ActionTypes;
        payload: {
            data: RolePayload;
            isLoading: boolean;
        };
    }>,
    email: string
) => {
    dispatch({
        type: ActionTypes.UPDATE_ROLE,
        payload: {
            data: {
                roleName: [ROLES.TEACHING_ASSISTANT],
                userId: '',
                email: '',
            },
            isLoading: true,
        },
    });
    const response = await PaulaClient.GetTeacherList({
        email,
    });
    let roleName = [ROLES.TEACHING_ASSISTANT];
    let userId = '';
    if (response.teachers?.length === 1) {
        roleName = [ROLES.TEACHER];
        userId = response?.teachers[0]?.id ?? '';
    }
    const result = { roleName, userId, email };
    dispatch({
        type: ActionTypes.UPDATE_ROLE,
        payload: {
            data: result,
            isLoading: false,
        },
    });
};

const RoleProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(roleReducer, initialState);
    const value = useMemo(() => ({ state, dispatch }), [state]);
    return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>;
};

export { RoleProvider, RoleContext, updateRole };
