/* eslint-disable no-unused-vars */
// false-positive when defining enum
export enum ROLES {
    TEACHER = 'teacher',
    TEACHING_ASSISTANT = 'teaching_assistant',
    CLASS_ASSISTANT = 'class_assistant',
}

export type RouteItem = {
    name: string;
    path: string;
    exact: boolean;
    showOnNavbar?: boolean;
    component: any;
    // any additional item arround the navigation items can be prefix, suffix or stuff
    additionalComponents?: Array<'notification-bubble'>; // List of Posibilities Value
    roles?: Array<ROLES>; // List of Posibilities Value
};
