const Index = () => {
    return (
        <div className="flex items-center justify-center w-screen min-h-screen bg-white">
            <div className="flex items-center justify-center w-10 h-10 bg-white rounded-full">
                <div className="absolute w-12 h-12 bg-green-200 rounded-full opacity-75 animate-ping" />
                <div className="absolute w-16 h-16 bg-green-400 rounded-full opacity-75 animate-ping" />
                <div className="absolute w-24 h-24 bg-green-300 rounded-full opacity-75 animate-ping" />
            </div>
        </div>
    );
};

export default Index;
