/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as GoogleProtobufEmpty from "../../../google/protobuf/empty.pb"
import * as GoogleProtobufTimestamp from "../../../google/protobuf/timestamp.pb"
import * as fm from "@generated/fetch.pb"
export type GetScheduleRequest = {
    email?: string
}

export type GetTeacherFeedbackListRequest = {
    email?: string
    status?: number
}

export type SessionTeacher = {
    id?: string
    name?: string
    from?: string
    image?: string
    email?: string
}

export type Session = {
    link?: string
    btsId?: string
    courseId?: string
    useBts?: boolean
    id?: string
    title?: string
    grade?: string
    topic?: string
    startTime?: GoogleProtobufTimestamp.Timestamp
    endTime?: GoogleProtobufTimestamp.Timestamp
    teacher?: SessionTeacher
    elective?: Elective
    learningMaterials?: LearningMaterial[]
    learningUnit?: LearningUnit
    btsSeatMapAccessCode?: string
}

export type Elective = {
    tag?: string
}

export type LearningMaterial = {
    id?: string
    deliveryTypes?: string[]
    learningCycle?: string
    learningUnitId?: string
    link?: string
    sequence?: string
    title?: string
    type?: string
}

export type LearningUnit = {
    id?: string
    theme?: string
    topic?: string
    levels?: string[]
}

export type GetScheduleResponse = {
    schedules?: Session[]
}

export type ParticipationRecord = {
    garageStudentId?: string
    studentName?: string
    attendance?: boolean
    punctuality?: boolean
    participation?: string
    positiveStandouts?: string
    negativeStandouts?: string
    assignmentFeedback?: string
    createdBy?: string
    updatedBy?: string
    createdAt?: GoogleProtobufTimestamp.Timestamp
    updatedAt?: GoogleProtobufTimestamp.Timestamp
}

export type TrackParticipationRequest = {
    btsRoomId?: string
    requester?: string
    records?: ParticipationRecord[]
}

export type GetStudentListRequest = {
    sessionId?: string
}

export type GetSessionRequest = {
    sessionId?: string
}

export type CheckParticipationTrackerLockRequest = {
    roomId?: string
    students?: string[]
    lockFor?: string
}

export type StudentLockRecord = {
    id?: string
    currentLockBy?: string
    currentLockSince?: GoogleProtobufTimestamp.Timestamp
}

export type ParticipationTrackerLockResponse = {
    students?: StudentLockRecord[]
}

export type StudentLockRequest = {
    id?: string
    currentLockBy?: string
}

export type LockParticipationTrackerRequest = {
    roomId?: string
    students?: StudentLockRequest[]
    lockFor?: string
}

export type SessionStudent = {
    id?: string
    name?: string
    activeSubscription?: boolean
    nickname?: string
    isTrial?: boolean
}

export type GetStudentListResponse = {
    students?: SessionStudent[]
}

export type GetParticipationRecordsRequest = {
    roomId?: string
}

export type GetParticipationRecordsResponse = {
    roomId?: string
    records?: ParticipationRecord[]
}

export type GetStageJoiningLinkRequest = {
    roomId?: string
    joineeId?: string
    joineeName?: string
    joineeType?: string
}

export type GetStageJoiningLinkResponse = {
    path?: string
}

export type GetZoomJoiningLinkRequest = {
    sessionId?: string
    email?: string
    participantName?: string
}

export type GetZoomJoiningLinkResponse = {
    link?: string
    hostKey?: string
}

export type GetTeacherListRequest = {
    email?: string
}

export type Teacher = {
    id?: string
    name?: string
    email?: string
}

export type TeacherList = {
    teachers?: Teacher[]
}

export type TeacherFeedbackRecord = {
    courseId?: string
    sessionId?: string
    roomId?: string
    teacherId?: string
    studentId?: string
    attendance?: boolean
    punctuality?: boolean
    participation?: number
    positiveStandouts?: string
    negativeStandouts?: string
    assignmentFeedback?: string
    levelRecommendation?: number
    isFinal?: boolean
}

export type TeacherFeedbackRequest = {
    requester?: string
    records?: TeacherFeedbackRecord[]
}

export type GetTeacherFeedbackRequest = {
    sessionId?: string
}

export type TeacherFeedbackSessionRecord = {
    title?: string
    teacher?: string
    topic?: string
    levelMin?: number
    levelMax?: number
    sessionId?: string
    courseId?: string
    roomId?: string
    startTime?: GoogleProtobufTimestamp.Timestamp
    endTime?: GoogleProtobufTimestamp.Timestamp
}

export type GetTeacherFeedbackRecord = {
    studentId?: string
    studentName?: string
    studentNickname?: string
    isTrial?: boolean
    attendance?: boolean
    punctuality?: boolean
    participation?: number
    positiveStandouts?: string
    negativeStandouts?: string
    assignmentFeedback?: string
    levelRecommendation?: number
    isFinal?: boolean
    createdBy?: string
    updatedBy?: string
    createdAt?: GoogleProtobufTimestamp.Timestamp
    updatedAt?: GoogleProtobufTimestamp.Timestamp
    isAbsent?: boolean
}

export type GetTeacherFeedbackResponse = {
    students?: GetTeacherFeedbackRecord[]
    session?: TeacherFeedbackSessionRecord
}

export type GetIncompleteTeacherFeedbackCountRequest = {
    email?: string
}

export type GetIncompleteTeacherFeedbackCountResponse = {
    count?: string
}

export type UpsertTeacherFeedbackStatusRequest = {
    sessionId?: string
    teacherId?: string
    status?: number
    sessionStartTime?: GoogleProtobufTimestamp.Timestamp
    updatedBy?: string
}

export type GetTeacherFeedbackForStudentRequest = {
    studentId?: string
    startTime?: string
    endTime?: string
}

export type TeacherFeedback = {
    courseId?: string
    sessionId?: string
    teacherId?: string
    studentId?: string
    attendance?: boolean
    punctuality?: boolean
    participation?: number
    levelRecommendation?: number
}

export type GetTeacherFeedbackForStudentResponse = {
    feedbacks?: TeacherFeedback[]
}

export type InitializeGoogleOauthResponse = {
    link?: string
}

export type VerifyOauthRequest = {
    code?: string
    authuser?: string
    scope?: string
}

export type Token = {
    accessToken?: string
    refreshToken?: string
}

export type VerifyOauthResponse = {
    token?: Token
}

export type VersionResponse = {
    name?: string
    env?: string
    version?: string
    builtAt?: string
    startedAt?: string
    uptime?: string
}

export class Paula {
    static GetSchedule(req: GetScheduleRequest, initReq?: fm.InitReq): Promise<GetScheduleResponse> {
        return fm.fetchReq<GetScheduleRequest, GetScheduleResponse>(`/v1/schedule?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
    }
    static GetSession(req: GetSessionRequest, initReq?: fm.InitReq): Promise<Session> {
        return fm.fetchReq<GetSessionRequest, Session>(`/v1/session/${req["sessionId"]}?${fm.renderURLSearchParams(req, ["sessionId"])}`, {...initReq, method: "GET"})
    }
    static GetStudentList(req: GetStudentListRequest, initReq?: fm.InitReq): Promise<GetStudentListResponse> {
        return fm.fetchReq<GetStudentListRequest, GetStudentListResponse>(`/v1/session/${req["sessionId"]}/students?${fm.renderURLSearchParams(req, ["sessionId"])}`, {...initReq, method: "GET"})
    }
    static TrackParticipation(req: TrackParticipationRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
        return fm.fetchReq<TrackParticipationRequest, GoogleProtobufEmpty.Empty>(`/v1/session/participation`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
    }
    static GetParticipationRecords(req: GetParticipationRecordsRequest, initReq?: fm.InitReq): Promise<GetParticipationRecordsResponse> {
        return fm.fetchReq<GetParticipationRecordsRequest, GetParticipationRecordsResponse>(`/v1/session/participation?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
    }
    static Ping(req: GoogleProtobufEmpty.Empty, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
        return fm.fetchReq<GoogleProtobufEmpty.Empty, GoogleProtobufEmpty.Empty>(`/ping?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
    }
    static Version(req: GoogleProtobufEmpty.Empty, initReq?: fm.InitReq): Promise<VersionResponse> {
        return fm.fetchReq<GoogleProtobufEmpty.Empty, VersionResponse>(`/version?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
    }
    static GetStageJoiningLink(req: GetStageJoiningLinkRequest, initReq?: fm.InitReq): Promise<GetStageJoiningLinkResponse> {
        return fm.fetchReq<GetStageJoiningLinkRequest, GetStageJoiningLinkResponse>(`/v1/session/link?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
    }
    static GetZoomJoiningLink(req: GetZoomJoiningLinkRequest, initReq?: fm.InitReq): Promise<GetZoomJoiningLinkResponse> {
        return fm.fetchReq<GetZoomJoiningLinkRequest, GetZoomJoiningLinkResponse>(`/v1/session/zoom_link?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
    }
    static CheckParticipationTrackerLock(req: CheckParticipationTrackerLockRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
        return fm.fetchReq<CheckParticipationTrackerLockRequest, GoogleProtobufEmpty.Empty>(`/v1/session/participation/requestLock`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
    }
    static LockParticipationTracker(req: LockParticipationTrackerRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
        return fm.fetchReq<LockParticipationTrackerRequest, GoogleProtobufEmpty.Empty>(`/v1/session/participation/lock`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
    }
    static GetTeacherList(req: GetTeacherListRequest, initReq?: fm.InitReq): Promise<TeacherList> {
        return fm.fetchReq<GetTeacherListRequest, TeacherList>(`/v1/teachers?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
    }
    static GetScheduleV2(req: GetScheduleRequest, initReq?: fm.InitReq): Promise<GetScheduleResponse> {
        return fm.fetchReq<GetScheduleRequest, GetScheduleResponse>(`/v2/schedule?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
    }
    static GetTeacherFeedbackList(req: GetTeacherFeedbackListRequest, initReq?: fm.InitReq): Promise<GetScheduleResponse> {
        return fm.fetchReq<GetTeacherFeedbackListRequest, GetScheduleResponse>(`/feedbacks?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
    }
    static PostTeacherFeedback(req: TeacherFeedbackRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
        return fm.fetchReq<TeacherFeedbackRequest, GoogleProtobufEmpty.Empty>(`/v1/session/feedback`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
    }
    static GetIncompleteTeacherFeedbackCount(req: GetIncompleteTeacherFeedbackCountRequest, initReq?: fm.InitReq): Promise<GetIncompleteTeacherFeedbackCountResponse> {
        return fm.fetchReq<GetIncompleteTeacherFeedbackCountRequest, GetIncompleteTeacherFeedbackCountResponse>(`/incomplete_feedbacks?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
    }
    static UpsertTeacherFeedbackStatus(req: UpsertTeacherFeedbackStatusRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
        return fm.fetchReq<UpsertTeacherFeedbackStatusRequest, GoogleProtobufEmpty.Empty>(`/feedback_status`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
    }
    static GetTeacherFeedback(req: GetTeacherFeedbackRequest, initReq?: fm.InitReq): Promise<GetTeacherFeedbackResponse> {
        return fm.fetchReq<GetTeacherFeedbackRequest, GetTeacherFeedbackResponse>(`/v1/session/feedback/${req["sessionId"]}?${fm.renderURLSearchParams(req, ["sessionId"])}`, {...initReq, method: "GET"})
    }
    static GetTeacherFeedbackForStudent(req: GetTeacherFeedbackForStudentRequest, initReq?: fm.InitReq): Promise<GetTeacherFeedbackForStudentResponse> {
        return fm.fetchReq<GetTeacherFeedbackForStudentRequest, GetTeacherFeedbackForStudentResponse>(`/v1/feedback?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
    }
    static InitializeGoogleOauth(req: GoogleProtobufEmpty.Empty, initReq?: fm.InitReq): Promise<InitializeGoogleOauthResponse> {
        return fm.fetchReq<GoogleProtobufEmpty.Empty, InitializeGoogleOauthResponse>(`/oauth/google?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
    }
    static VerifyOauth(req: VerifyOauthRequest, initReq?: fm.InitReq): Promise<VerifyOauthResponse> {
        return fm.fetchReq<VerifyOauthRequest, VerifyOauthResponse>(`/oauth/verify`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
    }
}
